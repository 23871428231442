<template>
 <PageHeading
  :has-meta-data="false"
  :has-action-button="false"
  :has-search="true"
  :title="$t('documents')"
  @update:search="$emit('update:search', $event)"
 />

 <div v-if="tbody?.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <DocumentBody
      :search="search"
      :thead="thead"
      :tbody="filteredDatas()"
      :data="data"
      @sort="(this.currentSort = $event), sort($event)"
      @action-detail="(...args) => openDetail(...args)"
     />
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('document') })"
   :subtitle="$t('emptyStateSubtitle', { title: $t('document') })"
  />
 </div>
</template>

<script>
import axios from "axios";

import PageHeading from "../components/PageHeading.vue";
import DocumentBody from "../components/DocumentBody.vue";
import EmptyState from "../components/EmptyState.vue";

const account = localStorage.getItem("account");

export default {
 name: "Documents",
 props: ["search"],
 components: {
  PageHeading,
  EmptyState,
  DocumentBody,
 },
 data() {
  return {
   account,
   currentSort: "id",
   currentSortDir: "desc",
   searchMonth: "",
   searchYear: "",
   searchNumb: "",
   searchTotalWithTax: "",
   searchTotalWithoutTax: "",
   thead: [
    { name: "documentName", sort: "fileDescription", isSort: false },
    { name: "created_at", sort: "created_at", isSort: false },
   ],
   tbody: [],
  };
 },
 methods: {
  async getDatas() {
   let id = this.account.slice(2, this.account.length);
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/documents?documentable_type=App\\Customer&documentable_id=${id}&customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.tbody = res.data;
   } catch (error) {
    console.error(error);
    //this.errorHandling(error);
   } finally {
    this.$emit("setLoading", false);
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedDatas() {
   return this.tbody.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "created_at") {
     if (new Date(a[toBeSorted]) < new Date(b[toBeSorted])) return -1 * modifier;
     if (new Date(a[toBeSorted]) > new Date(b[toBeSorted])) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },

  filteredDatas() {
   return this.sortedDatas().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();

     const fileNameMatch = res.fileName.toLowerCase().includes(searchLower);
     const fileDescriptionMatch = res.fileDescription.toLowerCase().includes(searchLower);
     const createdAtMatch = this.$d(res.created_at, "shortText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());

     return fileNameMatch || fileDescriptionMatch || createdAtMatch;
    }
    return res;
   });
  },

  async openDetail(...args) {
   const id = args[0];
   const mimeType = args[1];
   const account = args[2];
   this.$emit("setLoading", true);
   const res = await axios
    .get(
     `${this.$cookie.getCookie("API")}/api/v1/documents/${id}/content?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
     }
    )

    .catch((err) => {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
   const file = new Blob([await res.data], { type: mimeType });

   const fileURL = URL.createObjectURL(file);
   if (!res.data) {
    let err = this.$t("documentNotFound");
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", err);
   } else {
    window.open(fileURL);
   }
   this.$emit("setLoading", false);
  },
 },
 mounted() {
  this.getDatas();
 },
};
</script>

<style></style>
